@import "../variables";

.toptenlists-list {
	.tabs {
		display: flex;
		justify-content: center;
		margin: 6px 0;

		ul {
			border: 1px solid $color-selector-border;
			border-radius: 12px;
			float: left;
			list-style-type: none;
			margin: 0;
			overflow: hidden;
			padding: 0;
		}

		li {
			float: left;
		}

		button {
			background: transparent;
			border: 0;
			color: $color-selector-text;
			cursor: pointer;
			display: inline-block;
			padding: 6px 12px;
			text-decoration: none;

			&:focus {
				outline-offset: -5px;
				outline-style: solid;
				outline-width: 1px;
			}

			&.selected {
				background-color: $color-selector-background-selected;
				color: $color-selector-text-selected;
			}
		}
	}

	.create-toptenlist {
		margin: 8px 0;
	}

	.top-level-toptenlists-control {
		margin-top: 8px;

		.form-check-input {
			margin: 8px 0;
			position: relative;
		}

		label {
			font-size: 1rem;
		}
	}

	.download-my-toptenlists {
		margin: 8px 0;
	}

	.public-toptenlists-controls {
		margin-bottom: 8px;
		
		input {
			display: inline-block;
			margin-left: 8px;
			width: 15em;
		}

		select {
			display: inline-block;
			margin-left: 8px;
			width: 10em;
		}
	}
}
