@import '../variables';

.pagination {
	justify-content: center;

	button {
		color: $color-link;
		padding-right: 0.2rem;

		&:hover {
			text-decoration: underline;;
		}
	}

	.active {
		button {
			color: $color-text;
		}
	}

	.spacer {
		width: 1em;
	}
}