@import "../variables";

.organizer {
	float: left;
	margin: 0 8px 8px 0;

	&.open {
		clear: both;
	}

	.toptenlists {
		background: $color-organizer-background;
		border: 1px solid $color-organizer-border;
		border-radius: 8px;
		margin-top: 8px;
		padding: 8px;

		.inner-tube {
			max-height: 500px;
			overflow: auto;
		}
	}

	.organize {
		padding: 1px 6px;
	}

	.btn-primary {
		margin-left: 8px;
	}
}

.organizer-list {
	&.row {
		margin: 4px 0;
	}

	.show-toptenitems {
		float: left;
		width: 36px;
	}

	.name {
		display: inline-block;
		font-weight: bold;
		margin-left: 12px;
		padding: 6px 0;
	}

	.toptenitems {
		margin-left: 56px;
	}

	.toptenitem {
		display: inline-block;
		width: 100%;

		& > span {
			background: $color-organizer-toptenitem-background;
			border: 1px solid transparent;
			color: $color-organizer-toptenitem-text;
			float: left;
			padding: 6px 12px 6px 8px;

			.order {
				display: inline-block;
				padding: 0 6px 0 0;
				text-align: right;
				width: 2.2rem;
			}
		}

		.child-toptenlist {
			color: $color-hint;
		}

		&.selected {
			& > span {
				background: $color-organizer-toptenitem-selected-bg;
				border: 1px solid $color-organizer-toptenitem-selected;
				border-radius: 1rem;
			}

			.child-toptenlist {
				background: transparent;
				border: 1px solid transparent;
			}
		}
	}
}
