
// for use in components
$color-valid: #28a745;
$color-invalid: #dc3545;

$color-panel-border: #999;

$color-placeholder: #aaa;

$color-text: #333;
$color-link: #007bff;
$color-hint: #666;

// Navbar
$color-nav-hover: rgba(255, 255, 255, 0.7);
$color-notifications-background: #fff;
$color-notifications-unread-background: #eee;
$color-notifications-border: #ccc;
$color-notifications-delete: #aaa;
$color-notifications-delete-hover: #000;
$color-notifications-header-bg: #eee;
$color-notifications-count: #fff;
$color-notifications-count-bg: #c82333;

// horizontal selector
$color-selector-border: #424a6c;
$color-selector-text: #424a6c;
$color-selector-background-text: #fff;
$color-selector-text-selected: #fff;
$color-selector-background-selected: #424a6c;

// ListSummary
$color-topTenList-summary-bg: #fff;
$color-topTenList-fadeout: rgba(255, 255, 255, 0);

// ListDetail
$color-privacy-warning: #dc3545;

// Buttons
$color-btn-default-border: #ccc;

// EditableTextField
$color-hint: #666;

// organizer
$color-organizer-toptenitem-text: #212529;
$color-organizer-toptenitem-background: #eee;
$color-organizer-toptenitem-selected: #212529;
$color-organizer-toptenitem-selected-bg: #fff;
$color-organizer-border: #666;
$color-organizer-background: #eee;

// search
$color-search-border: #ccc;
$color-search-background: #fff;
$color-search-detail: #999;

// reusableItem
$color-reusableitem-border: #666;
$color-dropdown-definition: #666;
