.reusable-item {
	label {
		height: auto;
		margin: 0;
		position: relative;
		width: auto;
	}

	.header {
		font-weight: normal;
	}
}
