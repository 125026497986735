@import "../variables";

.reusableitem-detail {
	h2 {
		font-size: 1.5rem;

		.icon {
			margin-right: 8px;
		}
	}

	h3 {
		font-size: 1.3rem;
	}

	.help-icon {
		display: inline-block;

		button {
			border: 0;
			margin: 0 8px;
			padding: 0;
		}
	}

	.about {
		color: $color-hint;
		font-size: 0.8rem;
	}

	.main {
		border: 1px solid $color-reusableitem-border;
		border-radius: 10px;
		margin: 10px 0;
		padding: 10px;
	}

	.toptenlists {
		margin-top: 8px;

		input {
			margin-left: 0;
			position: relative;
		}
	}

	.toptenlists-list {
		margin: 8px 0;
	}

	.sort-controls {
		margin-top: 8px;
	}

	.sort-by {
		display: inline-block;
	}

	.sort-direction {
		display: inline-block;

		div {
			display: inline-block;
		}

		label {
			margin-left: 2rem;
		}
	}
}

.popover {
	.definition {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
