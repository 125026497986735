@import "../variables";

.search {
	position: relative;

	.results {
		background: $color-search-background;
		border: 1px solid $color-search-border;
		position: absolute;
		width: 230px;
		z-index: 10;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		border-bottom: 1px solid $color-search-border;
		padding: 4px;

		&:last-child {
			border-bottom: 0;
		}

		.name {
			display: inline-block;
			width: 100%;
		}

		.detail {
			color: $color-search-detail;
			display: inline-block;
			max-height: 2em;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;

			svg {
				margin-right: 8px;
			}
		}

		.is-public {
			background-position: center;
			background-repeat: no-repeat;
			background-size: 1rem 1rem;
			display: inline-block;
			margin-right: 8px;
			width: 1rem;
		}
	}

	a {
		color: $color-text;
		display: inline-block;
		text-decoration: none;
		width: 100%;

		&:hover {
			color: $color-text;
			text-decoration: none;
		}
	}

	.icon {
		margin-right: 10px;
	}
}

.navbar-collapse.show .search .results {
	width: 100%;
}
