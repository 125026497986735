@import "../variables";

.notifications-nav {
	display: inline-block; // address inconsistency between Chrome and Firefox
	position: relative;

	.badge.new {
		background: $color-notifications-count-bg;
		border-radius: 2px;
		color: $color-notifications-count;
		pointer-events: none;
		position: absolute;
		right: -15%;
		top: 0;
	}

	.btn {
		background: transparent;
		border: 0;
		width: 100%;

		&:hover {
			background: transparent;
		}

		&.delete-all {
			border-bottom: 1px solid $color-notifications-border;
			color: $color-text;
		}
	}

	.fa-bell {
		&:hover {
			color: $color-nav-hover !important;
		}
	}

	.notifications-list {
		background: $color-notifications-background;
		border: 1px solid $color-notifications-border;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
		left: -1.5rem;
		position: absolute;
		top: 3.2rem;
		width: 300px;
		z-index: 20;

		&::before {
			border: solid 15px transparent;
			border-bottom-color: $color-notifications-header-bg;
			content: "";
			left: 8px;                         // left position
			margin-left: 15px;                // adjust position, arrow has a height and width of 30px.
			pointer-events: none;
			position: absolute;
			top:-30px;
			z-index: 21;
		}

		.header {
			padding: 0 8px;
			background: $color-notifications-header-bg;
			border-bottom: 1px solid $color-notifications-border;
			display: block;
			width: 100%;
		}

		svg {
			margin-right: 8px;
		}

		a {
			white-space: nowrap;
		}

		ul {
			padding: 0;
		}

		li {
			border-bottom: 1px solid $color-notifications-border;
			list-style-type: none;
			padding: 0 8px;
			width: 100%;

			&:last-child {
				border-bottom: 0;
			}

			&.unread {
				background: $color-notifications-unread-background;
			}
		}

		.delete {
			float: right;

			button {
				color: $color-notifications-delete;

				&:hover {
					color: $color-notifications-delete-hover;
				}
			}
		}
	}
}
