@import "../variables";

.toptenitem-name {
	.rw-list {
		max-height: 400px;
	}

	.rw-list-optgroup {
		font-weight: bold;
	}

	.rw-list-option {
		font-weight: normal;
	}

	.combobox-dropdown {
		.icon {
			margin-right: 0.5rem;
		}

		.definition {
			color: $color-dropdown-definition;
			display: block;
			height: 1.5rem;
			overflow: hidden;
		}
	}

	.reusable-item {
		border: 1px solid $color-reusableitem-border;
		border-radius: 10px;
		margin: 10px 0 10px 0;
		padding: 10px;

		.icon {
			margin-right: 10px;
		}
	}
}
