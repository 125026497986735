@import "../variables";

.change-request-form {
	button {
		margin: 8px 8px 8px 0;
	}

	.hint {
		color: $color-hint;
		font-size: 0.8rem;
	}

	.invalid-feedback {
		display: block;

		&::before {
			content: "\2718 ";
		}
	}
}
