@import "../variables";

.toptenlist-detail-controls {
	float: right;

	.download-toptenlist {
		float: left;
	}

	.is-public {
		float: left;
		margin-left: 8px;
	}

	.btn-danger {
		float: left;
		margin-left: 8px;
	}
}

.toptenlist-detail {
	display: flex;
	justify-content: center;
	padding: 10px 0;

	> div {
		@media (min-width: 992px) {
			max-width: 720px;
		}

		width: 100%;
	}

	.breadcrumbs {
		float: left;
	}

	.icon {
		margin-right: 8px;
	}

	.hint {
		color: $color-hint;
	}

	.toptenlist-organizer {
		float: left;
		margin-left: 8px;
	}

	.toptenlist-name {
		// hide label but leave contents visible and entire label still readable
		label {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		.editable-text-field {
			float: left;
		}

		span {
			font-size: 1.5rem;
			font-weight: bold;

			&.hint {
				font-size: 1rem;
				font-weight: normal;
			}
		}

		.owner {
			clear: both;
			margin-bottom: 8px;

			svg {
				margin-right: 8px;
			}
		}
	}

	.toptenlist-description {
		max-height: none;

		// hide label but leave contents visible and entire label still readable
		label {
			border: 0;
			clear: both;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	.privacy-warning {
		color: $color-privacy-warning;
	}

	.info {
		margin-bottom: 8px;

		svg {
			margin-right: 8px;
		}
	}

	@media (min-width: 576px) {
		.toptenlist-modified-at {
			float: right;
		}
	}
}
