@import "../variables";

.toptenitem-header {
	font-weight: bold;

	.editable-text-field {
		margin-left: 30px;

		&.editing {
			float: none;
		}

		.placeholder {
			display: inline-block;
			padding: 6px 0;
		}
	}
}

.toptenitem-body {
	clear: both;
}

.toptenitem-container {
	border: 1px solid $color-panel-border;
	border-radius: 8px;
	margin: 0 8px 8px 0;
	padding: 8px;

	// hide label but leave contents visible and entire label still readable
	label {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	.order {
		float: left;
		margin-right: 8px;
		padding: 6px 0;
	}

	.name-icon {
		border: 0;
		float: left;
		margin-right: 8px;
		padding: 6px 0;
	}

	.is-public {
		float: left;
		margin-right: 8px;

		button {
			border: 0;
			padding: 0;
		}
	}

	button {
		&.create-childtoptenlist {
			margin-bottom: 10px;
		}

		&.move-up {
			margin-right: 6px;
		}
	}

	.child-toptenlist {
		padding: 6px 0;
	}

	.reusable-item {
		border: 1px solid $color-reusableitem-border;
		border-radius: 10px;
		margin: 10px 0 10px 0;
		padding: 10px;

		.icon {
			margin-right: 10px;
		}

		p {
			font-weight: normal;
		}
	}
}
