@import "../variables";

.editable-text-field {
	input {
		padding: 6px 12px;
	}

	.placeholder {
		color: $color-placeholder;
	}

	.btn-primary {
		margin-left: 8px;
	}

	.text {
		display: inline-block;
		margin: 6px 0;
		max-height: 3.2em;
		overflow: hidden;
		position: relative;

		&.expanded {
			max-height: none;

			.fader {
				display: none;
			}
		}

		// Markdown component inserts a p
		p {
			margin: 0 0 0.5em 0;
			padding: 0;
		}

		.fader {
			background: linear-gradient(to right, $color-topTenList-fadeout 0%, $color-topTenList-summary-bg 80%);
			display: block;
			height: 1.5em;
			position: absolute;
			right: 0;
			text-align: center;
			top: 1.8em;
			width: 4em;
		}
	}

	textarea {
		&.form-control {
			height: 120px;
		}
	}

	button {
		&.show-more {
			float: right;
			margin-bottom: 8px;
		}
	}

	.controls {
		margin-bottom: 6px;
	}

	.hint {
		color: $color-hint;
		font-size: 1rem !important; // eslint-disable-line no-important
		font-weight: normal !important; // eslint-disable-line no-important
		margin-left: 6px;

		a {
			color: $color-hint;
			text-decoration: underline;
		}
	}
}
