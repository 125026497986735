@import "../variables";

.download-toptenlist {
	button {
		height: 40px;
		width: 40px;
	}

	svg {
		vertical-align: top;
	}
}
