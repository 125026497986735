@import "../variables";

.create-toptenlist {
	.toptenlist-name {
		label {
			font-weight: bold;
		}
	}

	.toptenitem-name {
		label {
			font-weight: bold;
		}
	}

	.btn-primary {
		margin-left: 8px;
	}

	.toptenlist-description {
		label {
			border: 0;
			clear: both;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	.toptenitem-description {
		margin-top: 8px;

		label {
			border: 0;
			clear: both;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	.hint {
		color: $color-hint;
		font-size: 0.8rem;
	}
}
