@import "../variables";

.toptenlist-summary {
	border: 1px solid $color-panel-border;
	border-radius: 8px;
	margin: 0 8px 8px 0;
	overflow: hidden;
	padding: 8px;

	a {
		color: $color-text;

		&:hover {
			text-decoration: none;
		}
	}

	.toptenlist-name {
		float: left;
		font-weight: bold;
	}

	.toptenlist-summary-controls {
		float: right;

		.is-public {
			float: left;
		}

		.btn-danger {
			float: left;
			margin-left: 8px;
		}
	}

	.toptenlist-description {
		background: $color-topTenList-summary-bg;
		clear: both;
		display: block;
		line-height: 1.5em;
		max-height: 3em;
		overflow: hidden;
		position: relative;

		&::before {
			background: linear-gradient(to right, $color-topTenList-fadeout 0%, $color-topTenList-summary-bg 80%);
			content: "\200C";
			display: block;
			position: absolute;
			right: 0;
			text-align: center;
			top: 1.5em;
			width: 4em;
		}
	}

	.toptenlist-created-by {
		float: left;
		margin: 6px 0;

		svg {
			margin-right: 8px;
		}
	}

	.toptenlist-modified-at {
		float: right;
		margin: 6px 0;

		svg {
			margin-right: 8px;
		}
	}

	@media (min-width: 576px) {
		.toptenlist-modified-at {
			float: right;
		}
	}
}
